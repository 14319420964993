<template>
    <div ref="el" id="game" >
    </div>
</template>
<script>

import {PACMAN} from "../lib/pacman.js"

export default {
    name: "App",
    mounted() {
        PACMAN.init(this.$refs.el, "https://raw.githubusercontent.com/daleharvey/pacman/master/")
    }
}
</script>

<style scoped>
#game {
    max-width:600px;
}
</style>
