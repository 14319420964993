import { createApp } from "vue"
import Rails from "@rails/ujs"
Rails.start()


import Game from "../components/game.vue"

document.addEventListener("DOMContentLoaded", () => {
    const app = createApp(Game)
    app.mount("#main")
})
